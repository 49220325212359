<template>
  <nav class="navbar">
    <router-link to="/" class="navbar-logo">
      <img src="/Yeti_Minis_Logo.png" alt="Yeti Minis Logo" />
    </router-link>
    <router-link to="/about" class="navbar-link">About</router-link>
  </nav>
</template>

<script>
export default {
  name: 'SiteNavbar'
};
</script>

<style scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.8;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #919191;
  color: white;
  z-index: 1000;
}

.navbar-logo img {
  height: 50px;
}

.navbar-link {
  margin-left: 20px;
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.navbar-link:hover {
  text-decoration: underline;
}
</style>
