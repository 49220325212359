<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* Global styles can go here */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;
  font-family: Arial, sans-serif;
}

body {
  background: #BCBCBC;
}
</style>
