<template>
  <div>
    <SiteNavbar />
    <div class="canvas-container">
      <canvas ref="canvasRef" id="hero-lightpass"></canvas>
      <div class="overlay">
        <picture>
          <source srcset="/Yeti_Minis_Logo.webp" type="image/webp">
          <img src="/Yeti_Minis_Logo.png" alt="Yeti Minis Logo" class="logo" />
        </picture>
        <p class="description">Creators of the modern tabletop accessories</p>
      </div>
      <div class="scroll-indicator">
        <p class="scroll-text">Scroll Down</p>
        <picture>
          <source srcset="/downarrow.webp" type="image/webp">
          <img src="/downarrow.png" alt="Down Arrow" class="arrow" />
        </picture>
      </div>
      <div class="packaging" @click="goToPurchaseCombatLifter">
        <div class="popup-text">Buy Combat Lifter!</div>
        <picture>
          <source srcset="/PackagingMockup.webp" type="image/webp">
          <img src="/PackagingMockup.png" alt="Packaging Mockup" class="packaging-mockup" />
        </picture>
        <img src="/Promotion.svg" alt="Promotion SVG" class="promotion-svg" />
      </div>
      <div class="fullyeti" @click="goToAbout">
        <div class="popup-text">Learn More About Yeti!</div>
        <picture>
          <source srcset="/fullyeti.webp" type="image/webp">
          <img src="/fullyeti.png" alt="Fullyeti Mockup" class="packaging-mockup" />
        </picture>
        <img src="/Promotion.svg" alt="Promotion SVG" class="promotion-svg" />
      </div>
    </div>
    <br />
    <div class="spacer"></div>
  </div>
</template>

<script>
import { onMounted, ref, onBeforeUnmount } from 'vue';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SiteNavbar from './SiteNavbar.vue';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'HomePage',
  components: {
    SiteNavbar
  },
  setup() {
    const canvasRef = ref(null);

    const setCanvasSize = (canvas) => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const drawImageProp = (ctx, img, x, y, w, h) => {
      const iw = img.width;
      const ih = img.height;
      const scale = Math.max(w / iw, h / ih);
      const nw = iw * scale;
      const nh = ih * scale;
      const cx = (w - nw) / 2;
      const cy = (h - nh) / 2;

      ctx.drawImage(img, cx, cy, nw, nh);
    };

    onMounted(() => {
      window.scrollTo(0, 0);

      const canvas = canvasRef.value;
      if (!canvas) return;

      const context = canvas.getContext('2d');

      setCanvasSize(canvas);

      const frameCount = 330;
      const currentFrame = (index) => `/lifterAnimation/${(index + 1).toString().padStart(4, '0')}.jpg`;

      const images = [];
      const animation = {
        frame: 0
      };

      for (let i = 0; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        images.push(img);
      }

      gsap.to(animation, {
        frame: frameCount - 1,
        snap: 'frame',
        ease: 'none',
        scrollTrigger: {
          trigger: '.canvas-container',
          start: 'top top',
          end: `+=${3500 + window.innerHeight}`,
          markers: true,
          pin: true,
          scrub: 0.5,
          onLeave: () => {
            gsap.to('.overlay', { autoAlpha: 0 });
          },
          onEnterBack: () => {
            gsap.to('.overlay', { autoAlpha: 1 });
          }
        },
        onUpdate: render
      });

      images[0].onload = render;

      function render() {
        context.clearRect(0, 0, canvas.width, canvas.height);
        drawImageProp(context, images[animation.frame], 0, 0, canvas.width, canvas.height);
      }

      const handleResize = () => {
        setCanvasSize(canvas);
        render();
      };

      window.addEventListener('resize', handleResize);

      onBeforeUnmount(() => {
        window.removeEventListener('resize', handleResize);
      });

      // GSAP Animations for Packaging
      gsap.to('.packaging-mockup', {
        y: '-=20',
        rotation: 10,
        repeat: -1,
        yoyo: true,
        duration: 2,
        ease: 'power1.inOut'
      });

      gsap.to('.promotion-svg', {
        scale: 1.1,
        rotation: 360,
        repeat: -1,
        duration: 20,
        ease: 'none'
      });

      gsap.to('.promotion-svg', {
        scale: 1.2,
        yoyo: true,
        repeat: -1,
        duration: 1.5,
        ease: 'power1.inOut',
        delay: 0.5
      });

      // GSAP Animations for Fullyeti
      gsap.to('.fullyeti .packaging-mockup', {
        y: '-=20',
        rotation: 10,
        repeat: -1,
        yoyo: true,
        duration: 2.5, // Different duration for different timing
        ease: 'power1.inOut'
      });

      gsap.to('.fullyeti .promotion-svg', {
        scale: 1.1,
        rotation: 360,
        repeat: -1,
        duration: 22, // Different duration for different timing
        ease: 'none'
      });

      gsap.to('.fullyeti .promotion-svg', {
        scale: 1.2,
        yoyo: true,
        repeat: -1,
        duration: 1.7, // Different duration for different timing
        ease: 'power1.inOut',
        delay: 0.5
      });

      // GSAP Animation for Popup Text
      const packaging = document.querySelector('.packaging');
      const popupText = packaging.querySelector('.popup-text');

      packaging.addEventListener('mouseenter', () => {
        console.log('Hovered over packaging');
        gsap.to(popupText, { opacity: 1, y: '0px', duration: 0.5, ease: 'power1.inOut' });
        gsap.to(popupText, { rotation: 5, yoyo: true, repeat: -1, duration: 1, ease: 'power1.inOut' });
        gsap.to(popupText, { y: '-=10', yoyo: true, repeat: -1, duration: 1, ease: 'power1.inOut' });
      });

      packaging.addEventListener('mouseleave', () => {
        gsap.killTweensOf(popupText); // Stop all animations for popup text on mouse leave
        gsap.to(popupText, { opacity: 0, y: '-20px', duration: 0.5, ease: 'power1.inOut' });
      });

      const fullyeti = document.querySelector('.fullyeti');
      const fullyetiText = fullyeti.querySelector('.popup-text');

      fullyeti.addEventListener('mouseenter', () => {
        console.log('Hovered over fullyeti');
        gsap.to(fullyetiText, { opacity: 1, y: '0px', duration: 0.5, ease: 'power1.inOut' });
        gsap.to(fullyetiText, { rotation: 5, yoyo: true, repeat: -1, duration: 1, ease: 'power1.inOut' });
        gsap.to(fullyetiText, { y: '-=10', yoyo: true, repeat: -1, duration: 1, ease: 'power1.inOut' });
      });

      fullyeti.addEventListener('mouseleave', () => {
        gsap.killTweensOf(fullyetiText); // Stop all animations for popup text on mouse leave
        gsap.to(fullyetiText, { opacity: 0, y: '-20px', duration: 0.5, ease: 'power1.inOut' });
      });
    });

    const goToAbout = () => {
      window.location.href = '/about';
    };

    const goToPurchaseCombatLifter = () => {
      window.open('http://amazon.ca', '_blank');
    };

    return {
      canvasRef,
      goToAbout,
      goToPurchaseCombatLifter
    };
  }
};
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;
}

body {
  background: #bcbcbc;
}

canvas {
  width: 100vw;
  height: 100vh;
}

.canvas-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.spacer {
  width: 100%;
  height: 100vh;
  background: #bcbcbc;
}

.topSpacer {
  width: 100%;
  height: 10px;
  background: #bcbcbc;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.logo {
  width: 20vw; /* Adjusted for responsive sizing */
  max-width: 400px; /* Ensure it doesn't get too large */
}

.description {
  font-size: 1.2rem;
  margin-top: 10px;
}

.scroll-indicator {
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: white;
  font-family: 'Cute Font', cursive;
  font-size: 1.5rem;
  z-index: 1000;
}

.scroll-text {
  margin: 0;
  animation: bob 1.5s infinite;
}

.arrow {
  width: 2vw; /* Adjusted for responsive sizing */
  max-width: 24px; /* Ensure it doesn't get too large */
  margin: 5px auto;
  animation: bob 1.5s infinite;
}

.learn-more {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #32a852;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.learn-more:hover {
  background-color: #28a745;
}

.packaging {
  position: absolute;
  top: 30%;
  left: 10vw; /* Adjusted for responsive sizing */
  width: 18vw; /* Adjusted for responsive sizing */
  max-width: 350px; /* Ensure it doesn't get too large */
  max-height: 350px; /* Ensure it doesn't get too large */
  z-index: 1;
  text-align: center;
  cursor: pointer;
}

.fullyeti {
  position: absolute;
  top: 18%;
  right: 10vw; 
  width: 17vw; 
  max-width: 330px; 
  max-height: 350px; 
  z-index: 1;
  text-align: center;
  cursor: pointer;
}

.packaging-mockup {
  width: 100%;
  height: 100%;
  margin-top: 60%;
  object-fit: contain;
}

.promotion-svg {
  position: absolute;
  top: 43%;
  left: 52%;
  transform: translate(-50%);
  width: 20vw; /* Adjusted for responsive sizing */
  max-width: 400px; /* Ensure it doesn't get too large */
  z-index: -1;
  opacity: 0.7;
  pointer-events: none;
  fill: #32a852;
}

.popup-text {
  width: 700px;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2rem;
  font-weight: bold;
  opacity: 0;
  pointer-events: none;
  max-width: 300px;
  z-index: 4;
}

@keyframes bob {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style>
