<template>
  <div>
    <div id="vanta-bg"></div>
    <SiteNavbar />
    <span class="navbar-spacing"></span>
    <div class="timeline-container">
      <div class="hero-content">
        <h1 class="hero-title">Yeti's Story</h1>
        <p>The story of how a kid who really liked Dungeons and Dragons got a 3D printer, learned to model for his own games, and decided to modernize the tabletop gaming industry.</p>
        <picture>
          <source srcset="/floatingFounder.webp" type="image/webp">
          <img src="/floatingFounder.png" alt="Floating Founder" class="floating-image" />
        </picture>
      </div>
      <div class="timeline">
        <div class="timeline-line"></div>
        <div class="timeline-dot" ref="timelineDot"></div>
        <div class="timeline-item" @click="moveDot(0)" style="top: 5%;">
          <div class="timeline-year"></div>
        </div>
        <div class="timeline-item" @click="moveDot(1)" style="top: 30%;">
          <div class="timeline-year">2015</div>
          <div class="timeline-image">
            <img src="/timeline/etsy2015.jpg" alt="Image 2015" />
          </div>
          <div class="timeline-text">
            <h2>Humble Beginnings</h2>
            <p>At first, Yeti began as an Etsy store, printing affordable, high-quality miniatures that I enjoyed and knew others would enjoy in their games too.
               I worked with local and small artists online to bring their models into the hands of players. It was so fun to see photos of our miniatures in everyone's games,
                and it drove me to find better and more unique models to provide for everyone's games.
            </p>
          </div>
        </div>
        <div class="timeline-item" @click="moveDot(2)" style="top: 52%;">
          <div class="timeline-year">2020</div>
          <div class="timeline-image">
            <img src="/timeline/prototype2020.jpg" alt="Image 2020" />
          </div>
          <div class="timeline-text">
            <h2>The Idea</h2>
            <p>After years of selling, sculpting, and finding new models to sell on the store, one day while I was running a session, I needed some towers for my flying creatures. 
              Searching online brought up the same opaque, Lego-like tower that had been around since the inception of towers for tabletop games. Not happy with the choices and 
              not finding my perfect tower, I decided to model my own and integrate new modern features I wanted in a tower system.
            </p>
          </div>
        </div>
        <div class="timeline-item" @click="moveDot(3)" style="top: 74%;">
          <div class="timeline-year">2021</div>
          <div class="timeline-image">
            <img src="/timeline/kickstarter2020.jpg" alt="Image 2021" />
          </div>
          <div class="timeline-text">
            <h2>The Kickstart</h2>
            <p>After playtesting my tower, codenamed "Lifter," in a few of my games and getting big reactions and fun new combat engagements, I decided to take it to the next level
               and run a Kickstarter to get the product made with real molded plastic (instead of 3D printed), professional packaging, and the ability to ship it anywhere in the world!
                It seems like people agreed the tower needed a refresh because it was successfully funded within two weeks!
            </p>
          </div>
        </div>
        <div class="timeline-item" @click="moveDot(4)" style="top: 96%;">
          <div class="timeline-year">2024</div>
          <div class="timeline-image">
            <img src="/timeline/launch2024.jpg" alt="Image 2024" />
          </div>
          <div class="timeline-text">
            <h2>Our Vision for 2024+</h2>
            <p>It's time to take the tabletop world by storm. I have so many new product ideas and modules to add into the Combat Lifter system.
               I want to take all the things I wish I had while playing my tabletop games and make them a reality. My dream is to create a whole line of products,
                get them into the hands of players, and create an experience in their games like they've only seen on Twitch or YouTube.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue';
import gsap from 'gsap';
import SiteNavbar from './SiteNavbar.vue';
import * as THREE from 'three';

export default {
  name: 'AboutPage',
  components: {
    SiteNavbar
  },
  setup() {
    const timelineDot = ref(null);
    const timelinePositions = ref([]);
    const currentIndex = ref(0);
    let vantaEffect = null;

    onMounted(async () => {
      const timelineItems = document.querySelectorAll('.timeline-item');
      timelinePositions.value = Array.from(timelineItems).map(item => item.offsetTop);
      moveDot(currentIndex.value); // Start dot at the first timeline item
      animateDot(); // Start the periodic dot animation

      // Ensure THREE is globally accessible
      if (typeof window !== 'undefined') {
        window.THREE = THREE;
        const { default: FOG } = await import('vanta/dist/vanta.fog.min');
        vantaEffect = FOG({
          el: "#vanta-bg",
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.00,
          minWidth: 200.00,
          highlightColor: 0x84addc,
          midtoneColor: 0x6dc577,
          lowlightColor: 0x74b3fa,
          baseColor: 0xc8c8c8,
          speed: 1.90,
          THREE: window.THREE
        });
      }

      // GSAP animation for floating image
      gsap.to('.floating-image', {
        rotation: 15,
        yoyo: true,
        repeat: -1,
        ease: 'power1.inOut',
        duration: 1
      });
    });

    onUnmounted(() => {
      if (vantaEffect) vantaEffect.destroy();
    });

    const moveDot = (index) => {
      if (timelineDot.value && timelinePositions.value.length) {
        gsap.to(timelineDot.value, {
          duration: 1,
          top: `${timelinePositions.value[index] + 10}px`, // Adjust +10 as needed for alignment
          ease: 'power1.inOut',
          onComplete: () => {
            gsap.fromTo(timelineDot.value, { scale: 1.5 }, { scale: 1, duration: 0.5, ease: 'elastic.out(1, 0.5)' });
          }
        });
      }
    };

    const animateDot = () => {
      setInterval(() => {
        currentIndex.value = (currentIndex.value + 1) % timelinePositions.value.length;
        moveDot(currentIndex.value);
      }, 3000); // Change the interval as needed
    };

    return {
      timelineDot,
      moveDot
    };
  }
};
</script>

<style scoped>
#vanta-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hero-content {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.0); /* Optional: to make text stand out */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  z-index: 5;
}

.hero-title {
  font-size: 6rem; /* Doubled the size */
  margin-bottom: 10px;
  -webkit-text-stroke: 2px #000; /* Add stroke */
  text-stroke: 2px #000; /* For other browsers */
}

.hero-content p {
  margin-top: 6%;
  font-size: 1.2rem; /* Adjust as needed */
}

.floating-image {
  position: absolute;
  top: -15%;
  right: -11%; /* Adjust position as needed */
  width: 220px; /* Adjust size as needed */
}

.navbar-spacing {
  display: block;
  height: 60px; /* Adjusted to match the height of the navbar */
}

.timeline-container {
  position: relative;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  height: 120vh; /* Full height of the viewport */
}

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
  height: 100%;
}

.timeline-line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 120vh; /* Extend the height */
  background-color: #000;
  z-index: 0;
}

.timeline-dot {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-color: #32a852; /* Updated color */
  border-radius: 50%;
  z-index: 1;
}

.timeline-item {
  position: absolute;
  padding: 20px 0;
  cursor: pointer;
  width: 100%;
}

.timeline-year {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem;
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 2;
}

.timeline-image {
  position: absolute;
  left: calc(35% - 187.5px); /* Adjusted position */
  top: -80%;
  transform: translateX(-50%);
}

.timeline-image img {
  width: 340px; /* Increased size */
  height: auto;
  border-radius: 10px;
  border: 4px solid rgba(50, 168, 82, 0.5); /* Updated border */
}

.timeline-text {
  position: absolute;
  right: calc(45% - 300px); /* Adjusted position */
  top:-80%;
  transform: translateX(50%);
  max-width: 375px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.timeline-text h2 {
  margin: 0 0 10px 0;
}

.timeline-text p {
  margin: 0;
}
</style>
